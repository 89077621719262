import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "home-stats" }
const _hoisted_2 = { class: "text-gray-600 body-font" }
const _hoisted_3 = { class: "container px-5 py-24 mx-auto" }
const _hoisted_4 = { class: "um-stats-cover" }
const _hoisted_5 = ["textContent"]
const _hoisted_6 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.HomeStats, (stats, key) => {
            return (_openBlock(), _createElementBlock("div", {
              key: key,
              class: _normalizeClass(["um-stats", `stats-${stats.slug}`])
            }, [
              _createElementVNode("h2", {
                class: "um-stats-number",
                textContent: _toDisplayString(stats.number)
              }, null, 8, _hoisted_5),
              _createElementVNode("p", {
                class: "um-stats-name",
                textContent: _toDisplayString(stats.name)
              }, null, 8, _hoisted_6)
            ], 2))
          }), 128))
        ])
      ])
    ])
  ]))
}