
import { defineComponent } from "vue";
import { HomeStats } from "@/config/HomeConfig";

export default defineComponent({
  data() {
    return {
      HomeStats,
    };
  },
});
