import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page-home" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_home_intro = _resolveComponent("home-intro")!
  const _component_home_stats = _resolveComponent("home-stats")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_home_intro),
    _createVNode(_component_home_stats)
  ]))
}