
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return { inputText: "" };
  },
  methods: {
    submitForm() {
      this.$router.push({
        name: `NameMeaning`,
        params: { name: this.inputText },
      });
      // console.log("Submit", this.inputText);
    },
  },
});
