export const HomeStats: any = [
  {
    name: `Users`,
    slug: `users`,
    number: `2.7K`,
  },
  {
    name: `Names registered`,
    slug: `names-registered`,
    number: `15.5K`,
  },
  {
    name: `Views`,
    slug: `views`,
    number: `37.8K`,
  },
  {
    name: `Apps`,
    slug: `apps`,
    number: `2K`,
  },
];
